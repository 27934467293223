import { ANIMATED_ALERT_STATUS } from '@/consts/animatedAlertStatus';

export const closeAnimatedAlert = (
  setAlertStatus: (newAlertStatus: ANIMATED_ALERT_STATUS) => void
) => {
  setAlertStatus(ANIMATED_ALERT_STATUS.CLOSING);

  setTimeout(() => {
    setAlertStatus(ANIMATED_ALERT_STATUS.CLOSED);
  }, 700);
};
