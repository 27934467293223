import { IconHeart } from '@krakentech/icons';

import EnrichmentCard from '@/components/EnrichmentCard';
import { usePsrRenewalDue } from '@/hooks/accounts/usePsrRenewalDue';
import { INTERNAL_PATHS } from '@/utils/urls';

const PSREnrichmentCard = ({ onCardClose }: { onCardClose: () => void }) => {
  const { data, isLoading, isError } = usePsrRenewalDue();

  const PSRHref = `${INTERNAL_PATHS.PRIORITY_SERVICES_REGISTER.path}?viaDashboard=true`;

  if (!data || isLoading || isError) return null;

  return (
    <EnrichmentCard
      title="Let's update your Priority Services Register"
      subtitle="Let us know about any extra help or support you might need"
      icon={<IconHeart size={32} />}
      buttonActionTitle="Update"
      closeCard={onCardClose}
      href={PSRHref}
    />
  );
};

export default PSREnrichmentCard;
