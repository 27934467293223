import { useKrakenQuery } from '@/components/GraphQLClient';
import { useAuth } from '@/components/Providers/Auth';
import PsrRenewalDue from '@/hooks/accounts/usePsrRenewalDue/PsrRenewalDue.graphql';

import { psrRenewalDueSchema } from '../validations';

type PsrRenewalDue = {
  viewer: {
    isPsrRenewalDue: boolean;
  };
};

export const usePsrRenewalDue = () => {
  const { isAuthenticated } = useAuth();

  return useKrakenQuery({
    queryKey: ['psrRenewalDue'],
    query: PsrRenewalDue,
    select: (data: PsrRenewalDue) => data.viewer.isPsrRenewalDue,
    validateFn(response) {
      return psrRenewalDueSchema.validate(response);
    },
    enabled: Boolean(isAuthenticated),
  });
};
